import styled, { createGlobalStyle, keyframes } from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const shake = keyframes`
0% {
  transform: translateX(-1px);
}
50% {
  transform: translateX(5px);
}
100% {
  transform: translateX(0);
}
`

/* Alpacked global style */
const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans';
  }

  ol {
    counter-reset: item;
    padding-left: 0px;
  }
  ol li { display: block }

  ol li:before {
    content: counter(item) ". ";
    counter-increment: item;
    font-weight: bold;
    margin-right: 8px;
  }

  h1 {
    font-size: 82px;
    @media only screen and (max-width: 1200px) {
      font-size: 82px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 70px;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 60px;
    }
  }
  h2 {
    font-size: 40px;
    @media only screen and (max-width: 1200px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 36px;
    }
  }
  h3 {
    font-size: 28px;
    @media only screen and (max-width: 1200px) {
      font-size: 28px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 20px;
    }
  }
  h4 {
    font-size: 18px;
    @media only screen and (max-width: 1200px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 16px;
    }
  }
  p {
    color: ${themeGet("colors.black")};
    font-size: 18px;
    @media only screen and (max-width: 1200px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1200px) {
      font-size: 16px;
    }
  }

  a {
    color: ${themeGet("colors.mainBlue")};
    transition: 0.3s ease-in-out;
    &:hover {
     color: ${themeGet("colors.mainBlueHover")};
    }
  }

	.Overlay {
		position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
		z-index: 10000;
  }

	.Modal {
		position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 32px 20px 20px 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    max-width: 760px;
		@media (max-width: 568px) {
			width: 90%;
        max-width: 90%;
      }
	}

	.MuiCheckbox-colorSecondary.Mui-checked {
		color: ${themeGet("colors.mainBlue")};
	}

	.MuiFormControlLabel-root {
		align-items: flex-start;
		display: flex;
	}

	.MuiFormControlLabel-label {
		font-weight: 300;
		font-size: 14px;
		padding-top: 11px;
	}

  .scroll_to_top {
    position: fixed;
    left: -100px;
    bottom: 40px;
    transition: left 0.5s;
    cursor: pointer;
    font-size: 20px;
    padding: 10px;
  }

  .scroll_to_top_transition {
    left: 25px;
  }

	.scroll_


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${themeGet("colors.black")};
    font-family: 'Open Sans';
  }

  section {
    position: relative;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    color: ${themeGet("colors.black")};
  }

  .drawer-content-wrapper{
    @media (max-width: 767px) {
      width: 100%!important;
    }
    .drawer-content {
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #000;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li{
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          a{
            font-size: 20px;
            color: #fff;
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              color: #21AAD4;
            }
            &:before{
              content: '';
              width: 7px;
              height: 7px;
              background: #21AAD4;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }
          &.is-current {
            a {
              color: #21AAD4;
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
      .navbar_drawer_button button{
        width: 100%;
      }
    }

    .reusecore-drawer__close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
      &:before{
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: #fff;
        transform: rotate(45deg);
        display: block;
      }
    }
  }

  .sticky-nav-active {
    .alpacked_navbar {
      background: #000;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 12px 0;
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a {
            font-weight: 500;
            color: #6f6f6f;
            &:hover {
              color: #21AAD4;
            }
          }
          &.is-current {
            a {
              color: #21AAD4;
            }
          }
        }
      }
    }
    .hamburgMenu__bar {
      > span {
        background: #21AAD4;
      }
    }
  }

   .submenu_wrapper {
    background: #000;
    position: absolute;
    border-top: 1px solid #6f6f6f;
    left: 0;
    font-size: 14px;
    width: 100%;
    top: 78px;
    padding: 0px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s linear;
  }

   ul {
    li {
      &:hover .submenu_wrapper {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
  }



  .alpacked_navbar {
    position: fixed;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 12px 0;

    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 40px;

      li {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;

				@media (max-width: 1337px) {
        margin-bottom: 20px;
      }

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          a {
            color: #21AAD4;
          }
        }
        a {
          padding: 5px 5px 40px 5px;
          letter-spacing: 0.4px;
          font-size: 18px;
          font-weight: 500;
          color: #6f6f6f;
          transition: 0.15s ease-in-out;
          &:hover {
            color: #21AAD4;
          }
          @media (max-width: 1440px) {
          font-size: 18px;
        }
        }
      }
      @media (max-width: 1337px) {
        display: none;
      }
    }
    .navbar_button {
      @media (max-width: 1337px) {
        display: none;
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 1337px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }
`

export const PrevButton = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  &:hover {
    span {
      background: #3444f1;
      @media (min-width: 991px) {
        width: 100px;
      }
    }
  }
  span {
    width: 18px;
    height: 2px;
    background: #d1d3de;
    display: block;
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: "";
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(-45deg);
      top: -4px;
      left: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(45deg);
      width: 8px;
      bottom: -6px;
      left: 1px;
    }
  }
`

export const NextButton = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  &:hover {
    span {
      background: #3444f1;
      @media (min-width: 991px) {
        width: 100px;
      }
    }
  }
  span {
    width: 18px;
    height: 2px;
    background: #d1d3de;
    display: block;
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: "";
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(45deg);
      top: -4px;
      right: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(-45deg);
      width: 8px;
      bottom: -6px;
      right: 1px;
    }
  }
`

/* Alpacked wrapper style */
const AlpackedWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;

  /* .container {
    @media only screen and (min-width: 1220px) and (max-width: 1440px) {
      max-width: 1170px;
    }
  } */

  .navbar {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    padding: 22px 0;
    transition: padding 0.2s ease, background-color 0.1s ease;
    background-color: #000;

    &::before {
      width: calc(100% - 650px);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      background: linear-gradient(#0000006e, rgba(0, 0, 0, 0));
      right: 0;
      top: 0;
      z-index: -1;
      @media only screen and (max-width: 1440px) {
        width: calc(100% - 620px);
      }
      @media only screen and (max-width: 1360px) {
        width: calc(100% - 500px);
      }
      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .sticky-nav-active {
    .navbar {
      padding: 16px 0;
      background-color: ${themeGet("colors.white", "#ffffff")};
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

      &::before {
        display: none;
      }

      .smooth_scroll {
        color: ${themeGet("colors.heading", "#060F1E")};
        transition: color 0.3s ease;

        &:hover {
          color: ${themeGet("colors.text", "#294859")};
        }
      }
    }
  }

  .learn__more-btn {
    display: inline-flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    color: ${themeGet("colors.heading", "#060F1E")};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    height: 50px;
    min-width: 151px;
    padding: 0 20px;
    position: relative;
    @media only screen and (max-width: 1360px) {
      font-size: 13px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 40px;
      width: 72px;
      background-color: ${themeGet("colors.mainBlue")};
      transition: all 0.3s ease-out;
    }

    .btn_text {
      z-index: 1;
    }

    .hyphen {
      width: 20px;
      height: 2px;
      margin-right: 9px;
      background-color: ${themeGet("colors.heading", "#060F1E")};
      transform-origin: 50% 50%;
      z-index: 1;
    }

    &:hover {
      &::before {
        width: 100%;
      }

      .hyphen {
        animation: ${shake} 1s linear;
      }
    }
  }

  .reusecore-drawer__handler {
    .drawer_btn {
      border: 0;
      width: 80px;
      height: 100px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: fixed;
      top: 0;
      right: 60px;
      z-index: 99999;
      background-color: ${themeGet("colors.secondary", "#D50032")};
      transition: background-color 0.2s ease;
      @media only screen and (max-width: 1440px) {
        width: 75px;
      }
      @media only screen and (max-width: 1360px) {
        right: 30px;
      }

      &:hover {
        background-color: #e00032;
      }

      &:hover,
      &:focus {
        outline: 0;
      }

      ul {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 5px;
        height: 22px;
        width: 26px;
        transition: all 0.35s ease;
        @media only screen and (max-width: 1440px) {
          transform: scale(0.9);
        }
        @media only screen and (max-width: 667px) {
          transform: scale(0.8);
        }

        li {
          width: 5px;
          height: 4px;
          background-color: ${themeGet("colors.white", "#ffffff")};
        }
      }

      i {
        font-size: 22px;
        position: absolute;
        font-weight: 900;
        color: ${themeGet("colors.white", "#ffffff")};
        transform: scale(0.1);
        opacity: 0;
        visibility: hidden;
        transition: all 0.35s ease;
      }

      &.active {
        ul {
          opacity: 0;
          transform: scale(0.1);
        }

        i {
          opacity: 1;
          transform: rotate(45deg) scale(1);
          visibility: visible;
        }
      }
    }
  }
  /* Glide controls style */
  /* ------------------------------- */
  .glide {
    .glide__controls {
      margin-top: 30px;
    }

    .glide__controls > div,
    .glide__arrows > button {
      height: 18px;
      padding: 0;
      border: 0;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: transparent;

      .prev_arrow,
      .next_arrow {
        display: block;
        width: 24px;
        height: 2px;
        background-color: #c6c6c6;
        transition: width 0.3s ease;
        position: relative;
        @media only screen and (max-width: 667px) {
          width: 20px;
        }

        &::before,
        &::after {
          content: "";
          display: block;
          width: 14px;
          height: 2px;
          border-radius: 4px;
          background-color: #c6c6c6;
          position: absolute;
          z-index: 1;
          transition: all 0.3s ease;
        }

        &.next_arrow {
          &::before {
            right: 0;
            transform: rotate(0);
            transform-origin: top right;
          }
          &::after {
            right: 0;
            transform: rotate(0);
            transform-origin: 14px 2px;
          }
        }

        &.prev_arrow {
          &::before {
            left: 0;
            transform: rotate(0);
            transform-origin: top left;
          }
          &::after {
            left: 0;
            transform: rotate(0);
            transform-origin: 0 2px;
          }
        }
      }

      .next_arrow {
        margin-left: 15px;
      }

      &:hover {
        > span {
          width: 45px;
          border-radius: 4px;
          background-color: ${themeGet("colors.mainBlue", "#FDEF00")};
          @media only screen and (max-width: 667px) {
            width: 30px;
          }

          &::before,
          &::after {
            background-color: ${themeGet("colors.mainBlue", "#FDEF00")};
          }

          &.prev_arrow {
            &::before {
              transform: rotate(-42deg);
            }
            &::after {
              transform: rotate(42deg);
            }
          }

          &.next_arrow {
            &::before {
              transform: rotate(42deg);
            }
            &::after {
              transform: rotate(-42deg);
            }
          }
        }
      }
    }
  }
`

const ContentWrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  overflow: hidden;
`

export { GlobalStyle, AlpackedWrapper, ContentWrapper }
