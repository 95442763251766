const colors = {
  transparent: "transparent",
  mainBlue: "#21AAD4",
  mainBlueHover: "#0E93BC",
  lightBlue: "#E5F6FB",
  white: "#FFF",
  whiteHover: "#e9f6fa",
  lightGrey: "#F9F9F9",
  black: "#000",
  shadow: "rgba(38, 78, 118, 0.1)",
}

export default colors
